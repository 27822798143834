import React, { useState ,useEffect} from 'react';
import { Button, Form } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
import SimpleCrypto from 'simple-crypto-js';
import Modal from "react-bootstrap/Modal";
const Starter = () => {
  const [Maire, setMaire] = useState(localStorage.getItem('Maire'))
  const [Delegation, setDelegation] = useState(localStorage.getItem('Delegation'))
  const [Maire_ar, setMaire_ar] = useState(localStorage.getItem('Maire_ar'))
  const [Delegation_ar, setDelegation_ar] = useState(localStorage.getItem('Delegation_ar'))
  const [city, setCity] = useState(localStorage.getItem('city'))
  const [city_ar, setCity_ar] = useState(localStorage.getItem('city_ar'))
  const [etat_ar, setetat_ar] = useState(localStorage.getItem('etat_ar'))
  const [etat, setetat] = useState(localStorage.getItem('etat'))
  const [enclave, setenclave] = useState(localStorage.getItem('enclave'))
  const [enclave_ar, setenclave_ar] = useState(localStorage.getItem('enclave_ar'))

  
  
  const [Maireouautre, setMaireouautre] = useState(false)
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeMaire = () => {

    const encryptInit = new SimpleCrypto('PRIVATE_KEY_STORED_IN_ENV_FILE');
    const encryptedToken = encryptInit.encrypt( {
      "first_name": Maire,
      "address": Delegation,
      "type": Maireouautre

    });

    localStorage.setItem('Maire', Maire);
    localStorage.setItem('Delegation', Delegation);
    localStorage.setItem('Maire_ar', Maire_ar);
    localStorage.setItem('Delegation_ar', Delegation_ar);
    localStorage.setItem('Maireouautre', Maireouautre);
    localStorage.setItem('city', city);
    localStorage.setItem('city_ar', city_ar);
    localStorage.setItem('etat_ar', etat_ar);
    localStorage.setItem('etat', etat);
    localStorage.setItem('enclave', enclave);
    localStorage.setItem('enclave_ar', enclave_ar);

    // localStorage.setItem('tokenar', {
    //   "first_name": Maire_ar,
    //   "address": Delegation_ar,
    //   "type": Maireouautre

    // })
    handleShow();
  }
  const Testttt = () => {
    console.log( localStorage.getItem("Maire"))
    setMaireouautre(localStorage.getItem("Maireouautre"))

  }

  useEffect(() => {
   console.log(localStorage.getItem("Maireouautre"))
   
  }, [Maireouautre]);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Espace réservé pour le maire</Modal.Title>
        </Modal.Header>
        <Modal.Body>Données mises à jour avec succès!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <p>Espace réservé pour le maire</p>

      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Nom du Maire</Form.Label>
          <Form.Control type="text" defaultValue={Maire} placeholder="Enter le nom complet du maire" onChange={(e) => setMaire(e.target.value)} />

        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Commune</Form.Label>
          <Form.Control type="text" defaultValue={Delegation} placeholder="Entrer la commune" onChange={(e) => setDelegation(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Ville</Form.Label>
          <Form.Control type="text" defaultValue={city} placeholder="Entrer la ville" onChange={(e) => setCity(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Wilaya</Form.Label>
          <Form.Control type="text" defaultValue={etat} placeholder='Entrer  "Wilaya"' onChange={(e) => setetat(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Moughataa</Form.Label>
          <Form.Control type="text" defaultValue={enclave} placeholder='Entrer "Moughataa"' onChange={(e) => setenclave(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>إسم العمدة أو المفوّض مكانه</Form.Label>
          <Form.Control type="text" defaultValue={Maire_ar} placeholder="أدخل الإسم الكامل" onChange={(e) => setMaire_ar(e.target.value)} />

        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>البلديّة</Form.Label>
          <Form.Control type="text" defaultValue={Delegation_ar} placeholder="أدخل البلديّة" onChange={(e) => setDelegation_ar(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>الولاية</Form.Label>
          <Form.Control type="text" defaultValue={etat_ar} placeholder="أدخل الولاية" onChange={(e) => setetat_ar(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>المقاطعة</Form.Label>
          <Form.Control type="text" defaultValue={enclave_ar} placeholder="أدخل المقاطعة" onChange={(e) => setenclave_ar(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>المدينة</Form.Label>
          <Form.Control type="text" defaultValue={city_ar} placeholder="أدخل المدينة" onChange={(e) => setCity_ar(e.target.value)} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" defaultChecked={localStorage.getItem("Maireouautre")==="false"?false:true} value={Maireouautre} onChange={(e) => setMaireouautre(e.target.checked)} label="Données du délégant maire" />
        </Form.Group>
        <Button variant="primary" onClick={() => changeMaire()} type="submit">
          Confirmer
        </Button>
        {/* <Button variant="primary" onClick={() => Testttt()} type="submit">
          testtt
        </Button> */}
      </Form>
    </div>
  );
}

export default Starter;