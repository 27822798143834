import React, { useState, memo } from "react";
import { withFormik, Form, FastField, ErrorMessage } from "formik";
import * as Yup from "yup";
import ModalWithForm from "./modal-with-form";
import "./styles.css";

const Schema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .required("Required Name!"),
  email: Yup.string()
    .email("Invalid email Email!")
    .required("Required")
});

const FormMain = ({ refModal }) => {
  const [open, setOpen] = useState(false);
  return (
    <Form>
      <ModalWithForm
        isOpen={open}
        refModal={refModal}
        handleClose={() => setOpen(false)}
      />
      <div className="box">
        <label htmlFor="name">Name:</label>
        <FastField name="name" placeholder="name" />
        <span className="msg-error">
          <ErrorMessage name="name" />
        </span>
      </div>

      <div className="box">
        <label htmlFor="email">Email:</label>
        <FastField name="email" type="email " placeholder="email" />
        <span className="msg-error">
          <ErrorMessage name="email" />
        </span>
      </div>

      <button
        className="button-modal"
        type="button"
        onClick={() => setOpen(true)}
      >
        Modal
      </button>

      <button className="button" type="submit">
        Send
      </button>
    </Form>
  );
};

const enhanceWithFormik = withFormik({
  mapPropsToValues: () => ({ name: "", email: "" }),
  validationSchema: Schema,
  handleSubmit: values => {
    console.log(values);
  }
});

export default enhanceWithFormik(memo(FormMain));
