import React, { Component, Fragment } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import Invoice from './components/reports/Invoice'
import invoice from './data/invoice'

// import logo from './logo.svg';
import './App.css';
 import Get_cin_four from '../Formulaire/Formulaire';

function Act3_a_ar(props) {
  const [data, setData] = React.useState(
    {
      number:"",
      destination:"",
      person1: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        address: "",
        cin_date: "",
        civil_state:""
      },
      person2: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        address: "",
        cin_date: "",
        civil_state:""
      },
      person3: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        address: "",
        cin_date: "",
        civil_state:""
      },
      person4: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        address: "",
        cin_date: "",
        civil_state:""
      }

    }
  ); // the lifted state

  const sendDataToParent = (index) => { // the callback. Use a better name
    console.log(index);
    setData(index);
    console.log("data=>"+JSON.stringify(data));
    console.log("data type=>"+typeof(data));
  };
  React.useEffect(() => {
    console.log(data);
  }, [data]);


  return (

    <div>
      <Get_cin_four lang={"ar"} nbrperson={3} namebutton={"شهادة عدم زواج (مثول شخصي لمقدم الطلب)"}     citoyen1={"الشّخص الأوّل"}    citoyen2={"الشّخص الثّاني"}   citoyen3={"الشّخص الثّالث"}   citoyen4={"الشّخص الرّابع"} sendDataToParent={sendDataToParent} />

      <PDFViewer width="100%" height="800" className="100%" >
        <Invoice invoice={data} />
      </PDFViewer>

    </div>

  );
}

export default Act3_a_ar;