import React from 'react';
import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';
import slogan from '../../../assets/images/slogan_rim.png'
import logo from '../../../assets/images/logo1.png'
import moment from 'moment'

const styles = StyleSheet.create({

    titleContainer: {
        flexDirection: 'row',
        marginTop: 15,
    },
    logo: {
        width: 74,
        height: 74,
        textAlign: "center"
    },
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    redLine: {
        width: "100%",
        height: 1,
        backgroundColor: "red"
    },
    greenLine: {
        width: "100%",
        height: 1,
        backgroundColor: "green"
    },
    yellowLine: {
        width: "100%",
        height: 1,
        backgroundColor: "yellow"
    },
    containerLines: {
        width: "90%",
        position: "relative",
        top: -25,
        left: 59
    },
    sloganCenter: {
        marginTop: 20,
        marginLeft: 320
    },
    slogan: {
        width: 150,
        height: 50,
        textAlign: "center"
    },
    logoCenter: {
        position: "relative",
        top: -65,
        left: 500
    },
    actLeftContentCommune: {
        // marginLeft:'5%',
        width: 300,
        marginTop: -100,
        marginBottom: 50,
        fontSize: 8,
        textAlign: 'center'
    },
    actLeftCommuneAr: {
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft: 60,
        // marginTop:-60

    },
    actLeftMaire: {
        // textAlign:'left',
        marginLeft: 60,
        fontSize: 6
        // marginTop:40

    },
    actLeftMaireAr: {
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft: 60,
        // marginTop:40

    },

    contentLine: {
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '30%'
    },
    textNumberContainer: {
        marginTop: -30,
    },
    textNumber2: {
        fontSize: 8,
        fontFamily: 'fontarab',
        textAlign: "right",
        marginRight: -160,
    },
});


const HeadersSlogan = (props) => (
    <View style={{ marginBottom: -60 }} >
        <View style={styles.sloganCenter}>
            <Image style={styles.slogan} src={slogan} />
        </View>
        <View style={styles.containerLines}>
            <View style={styles.redLine}></View>
            <View style={styles.greenLine}></View>
            <View style={styles.yellowLine}></View>
        </View>
        <View style={styles.logoCenter}>
            <Image style={styles.logo} src={logo} />
        </View>
        <View style={styles.actLeftContentCommune}>
            <Text style={styles.actLeftCommuneAr}> وزارة الدّاخليّة و اللّامركزيّة</Text>
            <Text style={styles.actLeftMaire}>MINISTERE DE L'ENTERIEUR ET DE LA DECENTRALISATION</Text>
            <View style={styles.contentLine}><View style={styles.line}></View></View>
            <Text style={styles.actLeftCommuneAr}>{localStorage.getItem("etat_ar")} ولاية </Text>
            <Text style={styles.actLeftMaire}>WILAYA DE  {localStorage.getItem("etat")}</Text>
            <Text style={styles.actLeftCommuneAr}>{localStorage.getItem("enclave_ar")} مقاطعة </Text>
            <Text style={styles.actLeftMaire}>MAOUGHATAA DE {localStorage.getItem("enclave")}</Text>
            <Text style={styles.actLeftCommuneAr}>{localStorage.getItem("Delegation_ar")} بلديّة </Text>
            <Text style={styles.actLeftMaire}>COMMUNE DE  {localStorage.getItem("Delegation")}</Text>
            <Text style={styles.actLeftCommuneAr}>العمدة</Text>
            <Text style={styles.actLeftMaire}>Le Maire</Text>
            <View style={styles.textNumberContainer}>
                <Text style={styles.textNumber2}>{localStorage.getItem("city")}{" le "}{moment().format('DD/MM/YYYY')}{" في "}{" "}{localStorage.getItem("city_ar")}</Text>
                <Text style={styles.textNumber2}>Numéro : {props.number} :الرّقم </Text>
            </View>
        </View>


    </View>
);

export default HeadersSlogan