import React from 'react';
import { Page, Document, Image, View, Text, StyleSheet, Font } from '@react-pdf/renderer';

import logo from '../../../../../assets/images/logo1.png'
import slogan from '../../../../../assets/images/slogan_rim.png'
import fontBold from '../../../../../assets/franklin.ttf'
import fontArial from '../../../../../assets/arialItalic.ttf'
import moment from 'moment'
import HeadersSlogan from '../../../Headers/Headers'

var Maire = require('../../../Maire/Maire')()


Font.register({
    family: 'fontBold',
    src: fontBold
});
Font.register({
    family: 'fontArial',
    src: fontArial
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 74,
        textAlign: "center"
    },
    slogan: {
        width: 150,
        height: 50,
        textAlign: "center"
    },
    mainContent1: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20
    },
    logoCenter: {
        marginLeft: 260
    },
    sloganCenter: {
        marginTop: 5,
        marginLeft: 220
    },
    text: {
        fontSize: 10,
        paddingTop: 5,
        lineHeight: 1,
        fontWeight: "bold",
        fontStyle: "oblique",
        textAlign: "center",
    },
    actCenter: {
        width: 500,
        height: 30,
        backgroundColor: "#90bd9d",
        marginTop: 25,
        marginLeft: 100,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15
    },
    actLeft: {
        textAlign: 'right',
        marginRight: 150
    },
    actLeftContentCommune: {
        // marginLeft:'5%',
        width: 300,
        marginTop: -40,
        fontSize: 8,
        textAlign: 'center'
    },
    contentLine: {
        textAlign: 'center',
        marginLeft: '50%',
        marginRight: '30%'
    },
    line: {
        height: 0.7,
        backgroundColor: "black"
    },
    actLeftMaire: {
        // textAlign:'left',
        marginLeft: 60,
        fontSize: 6
        // marginTop:40

    },
    actLeftCommune: {
        // textAlign:'left',
        marginLeft: 60,
        // marginTop:-60

    },
    actLeftMaireAr: {
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft: 60,
        // marginTop:40

    },
    actLeftCommuneAr: {
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft: 60,
        // marginTop:-60

    },
    textNumberContainer: {
        marginTop: -30,
    },
    textNumber2: {
        fontSize: 8,
        fontFamily: 'fontarab',
        textAlign: "right",
        marginRight: 120,
    },
    text1: {
        fontSize: 10,
        paddingTop: 3,
        fontWeight: "bold",
        fontStyle: "oblique",
        textAlign: "left",
        marginTop: 5,
        marginLeft: 20,
        color: "black"
    },
    text2: {
        fontFamily: 'fontBold',
        fontSize: 12,
        paddingTop: 5,
        // fontWeight: "bold",
        // fontStyle: "oblique",
        textAlign: "right",
        // marginTop:5,
        marginBottom: 5,
        marginRight: 35,
    },
    block1: {
        marginLeft: 30,
        marginRight: 30,
    },
    minContent1: {
        fontFamily: 'fontBold',
        fontSize: 12,
        height: 20,
        textAlign: "left",
        marginTop: 5,
        fontWeight: 900

    },
    minContent2: {
        textAlign: "left",
        marginTop: 10,
    },
    minContent3: {
        height: 40,
        textAlign: "left",
        marginTop: 10,
    },
    minContent4: {
        height: 40,
        textAlign: "left",
        marginTop: 10,
        marginLeft: 20
    },
    minContent5: {
        textAlign: "left",
        // marginLeft:30
    },
    minContent6: {
        borderTop: "none",
        borderBottom: "none",
        height: 20,
        textAlign: "right",
        marginTop: 10,
        marginRight: 15
    },
    minContent7: {
        fontFamily: 'fontBold',
        fontSize: 12,
        height: 70,
        textAlign: "left",
        marginTop: 10,
    },
    checkbox: {
        width: 10,
        height: 10,
        borderWidth: 1
    },
    checkbox2: {
        width: 10,
        height: 10,
        borderWidth: 1,
        backgroundColor: "#000000"

    },
    checkboxList: {
        flexDirection: 'row',
        marginLeft: 30,
    },
    choiceLabel: {
        marginRight: 50,
        marginLeft: 5
    },
    choiceLabelLast: {
        marginRight: 20,
        marginLeft: 5
    },
    signatureContent: {
        flexDirection: 'row'
    },
    signatureParentContent: {
        marginTop: 70,
        marginRight: 30,
        width: 250,
        textAlign: "center"
    },
    signatureOne: {
        fontFamily: 'fontBold',
        fontSize: 8,
        width: 150,
        textAlign: "center",
        marginLeft: 15,
        marginRight: 15
    },
    signatureTwo: {
        fontFamily: 'fontBold',
        fontSize: 8,
        width: 200,
        textAlign: "center",
        marginLeft: 45,
        marginRight: 35,
        marginBottom: 10
    },
    signatureOneEmpty: {
        fontSize: 8,
        width: 150,
        height: 12,
        marginTop: 18,
        textAlign: "center",
        marginLeft: 15,
        marginRight: 15
    },
    signatureTwoEmpty: {
        fontSize: 8,
        width: 150,
        height: 12,
        textAlign: "center",
        marginLeft: 45,
        marginRight: 15
    },
    mainContent2: {
        // fontFamily: 'Helvetica',
        // fontSize: 8,
        lineHeight: 1.15,
        flexDirection: 'column',
        marginTop: 60,
        marginRight: 20,
        marginLeft: 20,
        height: 80,
        // backgroundColor:'#e4e9ec',
        flexDirection: 'row'
    },
    textFooter: {
        fontFamily: 'fontArial',
        fontSize: 9,
        paddingTop: 2,
        // fontWeight: "bold",
        // fontStyle: "oblique",
        textAlign: "justify",
        marginTop: 5,
        marginLeft: 10,
        color: 'black',
        width: 530,
        lineHeight: 1.15,
    }
});

function Invoice(props) {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.mainContent1}>
                     <HeadersSlogan number ={props.invoice.number} />

                    {/* <View style={styles.actLeftContentCommune}>
                        <Text style={styles.actLeftCommuneAr}> وزارة الدّاخليّة و اللّامركزيّة</Text>
                        <Text style={styles.actLeftMaire}>MINISTERE DE L'ENTERIEUR ET DE LA DETRALISATION</Text>
                        <View style={styles.contentLine}><View style={styles.line}></View></View>
                        <Text style={styles.actLeftCommuneAr}>{localStorage.getItem("Delegation_ar")} بلديّة </Text>
                        <Text style={styles.actLeftMaire}>COMMUNE DE  {localStorage.getItem("Delegation")}</Text>
                        <Text style={styles.actLeftCommuneAr}>العمدة</Text>
                        <Text style={styles.actLeftMaire}>Le Maire</Text>
                    </View>

                    <View style={styles.textNumberContainer}>
                        <Text style={styles.textNumber2}>{localStorage.getItem("city")}{" le "}{moment().format('DD/MM/YYYY')}{" في "}{" "}{localStorage.getItem("city_ar")}</Text>
                        <Text style={styles.textNumber2}>Numéro : {props.invoice.number} :الرّقم </Text>
                    </View> */}
                    <View style={styles.actCenter}>
                        <Text style={styles.text1}>Attestation de non salarié (Comparution personnelle du demandeur exigée)</Text>
                    </View>

                </View>
                <View style={styles.block1}>
                    <Text style={styles.minContent1}>  Destination :{props.invoice.destination}.</Text>
                    <Text><Text style={styles.minContent1}>  Durée de validité : </Text><Text>03 mois à compter de la date de sa signature.</Text></Text>
                    <Text style={styles.minContent3}>  Nous soussigné <Text style={styles.minContent1}>{localStorage.getItem("Maire") + " "}</Text>{localStorage.getItem("Maireouautre") === "true" ? "délégant maire" : "maire"} de la commune de <Text style={styles.minContent1}>{localStorage.getItem("Delegation")}</Text> certifions, sur la foi de la déclaration de : </Text>
                    <View style={styles.checkboxList}>
                        <View style={props.invoice.person1.civil_status === "Mr" ? styles.checkbox2 : styles.checkbox}></View>
                        <Text style={styles.choiceLabel}>M</Text>
                        <View style={props.invoice.person1.civil_status === "Mme" ? styles.checkbox2 : styles.checkbox}></View>
                        <Text style={styles.choiceLabel}>Mme</Text>
                        <View style={props.invoice.person1.civil_status === "Mlle" ? styles.checkbox2 : styles.checkbox}></View>
                        <Text style={styles.choiceLabel}>Mlle</Text>
                    </View>
                    <Text><Text style={styles.minContent5}>  Nom et prénom : <Text style={styles.minContent1}>{props.invoice.person1.first_name + " " + props.invoice.person1.last_name}</Text> titulaire de la carte nationale d’identification n° <Text style={styles.minContent1}>{props.invoice.person1.cin}</Text> du <Text style={styles.minContent1}>{props.invoice.person1.cin_date ? moment(props.invoice.person1.cin_date).format('DD/MM/YYYY') : ""}</Text> né(e) le <Text style={styles.minContent1}>{props.invoice.person1.date_of_birth ? moment(props.invoice.person1.date_of_birth).format("DD/MM/YYYY") : ""}</Text> à <Text style={styles.minContent1}>{props.invoice.person1.place_of_birth}</Text> domicilié(e) à <Text style={styles.minContent1}>{props.invoice.person1.address}</Text></Text><Text style={styles.minContent7}> nous a déclaré sur l’honneur n’exercer aucune activité salariée.</Text></Text>
                    <Text style={styles.minContent2}>  En foi de quoi nous avons délivré la présente attestation, que le (la) déclarant(e) a signé devant nous, pour servir et valoir ce que de droit.</Text>
                    {/* <Text style={styles.minContent6}>Fait à {localStorage.getItem("Delegation")} le {moment().format("DD/MM/YYYY")}</Text> */}
                    <View >
                        <View style={styles.signatureContent}>

                            <View style={styles.signatureParentContent}>
                                <Text style={styles.signatureTwo}>Signature de l’administré(e) attestant sur l’honneur</Text>
                                <Text style={styles.signatureTwo}>l’exactitude des renseignements portés ci-dessus</Text>
                                <Text style={styles.signatureTwo}>……………………………………………………</Text>
                            </View>
                            <View style={styles.signatureParentContent}>
                                <Text style={styles.signatureOne}>{localStorage.getItem("Maireouautre") === "true" ? "Pour le Maire et par Délégation" : "Le maire"}  </Text>
                                <Text style={styles.signatureOneEmpty}></Text>
                                <Text style={styles.signatureOne}>……………………………………………………</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.mainContent2}>
                    <Text style={styles.textFooter}>Le fait de contrefaire, de falsifier ou d’altérer ou le fait de se  faire délivrer indûment ou de tenter de se faire délivrer indûment tous documents délivrés par les administrations publiques en vue de constater un droit, une indemnité ou une qualité, d'accorder une autorisations, soit en faisant de fausses déclarations, soit en prenant un faux nom ou une fausse qualité, soit en fournissant de faux renseignements, certificats ou attestations, ainsi que le fait pour le fonctionnaire de  délivrer ou faire délivrer un de ces  documents sont des infractions prévues et sanctionnées par des amandes et des peine d’emprisonnement exemplaires énoncées aux articles 149 et 150 du code pénal.</Text>
                </View>
            </Page>
        </Document>
    )
};

export default Invoice