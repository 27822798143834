import React, { useEffect, useState } from 'react';
import {

    Button,
    Modal,
    InputGroup,
    FormControl,
    Row,
    Card,
    Col
} from "react-bootstrap";
import "./style.css";
import { Formik } from "formik";
import * as Yup from "yup";
import 'bootstrap/dist/css/bootstrap.min.css';
function Formulaire(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [data, setData] = useState([]);
    // const [inputList, setInputList] = useState([{ child_first_name1: "", child_last_name1: "", child_date_of_birth1: "", child_place_of_birth1: "" }]);
    const [inputList, setInputList] = useState([{ first_name: "", last_name : "",date_of_birth:"",place_of_birth:"",cin:""}])
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    
    let handleChangeInputs = (i, e) => {
        let newInputList = [...inputList];
        newInputList[i][e.target.name] = e.target.value;
        setInputList(newInputList);
      }

    let addFormFields = () => {
        setInputList([...inputList, { first_name: "", last_name : "",date_of_birth:"",place_of_birth:"",cin:""}])
      }
    
    let removeFormFields = (i) => {
        let newInputList = [...inputList];
        newInputList.splice(i, 1);
        setInputList(newInputList)
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { child_first_name1: "", child_last_name1: "", child_date_of_birth1: "", child_place_of_birth1: "" }]);
    };

    useEffect(() => {
        console.log("props.id")
    }, [])
    const handlSubmit = (values) => {

        console.log(values);
    }
    function handleShowPDF(valeeeee) {
        props.sendDataToParent(valeeeee);
        handleClose();
    }
    useEffect(() => {
        // console.log("show message value:",showMessage);
    }, [data]);
    return (
        <>
            <Button variant="secondary"
                onClick={handleShow}> <span className="fa fa-list"></span> {props.namebutton} </Button>


            <Modal dialogClassName="my-modal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {props.namebutton} </Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <Formik
                        initialValues={{
                            number:"",
                            destination:"",
                            first_name1: "",
                            last_name1: "",
                            cin1: "",
                            date_of_birth1: "",
                            place_of_birth1: "",
                            address1: "",
                            cin_date1: "",
                            cin_place1: "",
                            civil_state1: "",
                            role_state1:"",
                            first_name2: "",
                            last_name2: "",
                            cin2: "",
                            date_of_birth2: "",
                            place_of_birth2: "",
                            address2: "",
                            cin_date2: "",
                            cin_place2: "",
                            civil_state2: "",
                            destination2:"",
                            first_name3: "",
                            last_name3: "",
                            cin3: "",
                            date_of_birth3: "",
                            place_of_birth3: "",
                            address3: "",
                            cin_date3: "",
                            cin_place3: "",
                            civil_state3: "",
                            first_name4: "",
                            last_name4: "",
                            cin4: "",
                            date_of_birth4: "",
                            place_of_birth4: "",
                            address4: "",
                            cin_date4: "",
                            cin_place4: "",
                            civil_state4: "",
                            first_name5: "",
                            last_name5: "",
                            cin5: "",
                            date_of_birth5: "",
                            place_of_birth5: "",
                            address5: "",
                            cin_date5: "",
                            cin_place5: "",
                            civil_state5: "",
                            child_first_name1: "",
                            child_last_name1: "",
                            child_date_of_birth1: "",
                            child_place_of_birth1: "",
                            residence:"",
                            date_of_death:"",
                            start_date_of_living:"",
                            new_address:"",
                            paper_title1:"",
                            paper_title2:""

                        }}
                        validationSchema={
                            Yup.object().shape({
                                name_complete: Yup.string()
                                    .min(2, "i18n.t('error-short-full-name')")
                                    .required("i18n.t('error-required-full-name')"),

                            })
                        }
                        onSubmit={(values, { handleClose }) => {
                            console.log("values=>" + values)
                            // handleShowPDF()




                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <Row>

                                    <Col style={{ backgroundColor: "#dddddd" }}  >
                                        <div >
                                            <h3 style={{ textAlign: "center" }} >{props.lang=="fr"?"Données générales":"معلومات عامّة"}  </h3>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nombre":"الرّقم"}</InputGroup.Text>
                                                <FormControl
                                                    name="number"
                                                    placeholder={props.lang=="fr"?"Nombre":"الرّقم"}
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.number}
                                                />
                                            </InputGroup>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Destination":"الوجهة"}</InputGroup.Text>
                                            <FormControl
                                                name="destination"
                                                placeholder={props.lang=="fr"?"Destination":"الوجهة"}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.destination}
                                            />
                                        </InputGroup>
                                        {props.officialPapers &&
                                        <div>
                                            <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Document 1":"الوثيقة 1"}</InputGroup.Text>
                                                <FormControl
                                                    name="paper_title1"
                                                    placeholder={props.lang=="fr"?"Document 1":"الوثيقة 1"}
                                                    aria-label="document1"
                                                    aria-describedby="basic-addon1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.paper_title1}
                                                />
                                            </InputGroup>
                                            <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Document 2":"الوثيقة 2"}</InputGroup.Text>
                                                <FormControl
                                                    name="paper_title2"
                                                    placeholder={props.lang=="fr"?"Document 2":"الوثيقة 2"}
                                                    aria-label="document"
                                                    aria-describedby="basic-addon1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.paper_title2}
                                                />
                                            </InputGroup>
                                            </div>
                                        }
                                        {props.nbrperson > 0 &&
                                            <div >
                                                <h3 style={{ textAlign: "center" }} >{props.citoyen1}  </h3>
                                                {props.person1Role&&
                                                    <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Rôle":"الوظيفة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="role_state1"
                                                        placeholder={props.lang=="fr"?"Rôle":"الوظيفة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.role_state1}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un rôle":"أختر الوظيفة"}</option>
                                                        <option key={"man"} value={"Father"}>
                                                            {props.lang=="fr"?"Père":"الأب"}
                                                        </option>
                                                        <option key={"woman"} value={"Mother"}>
                                                            {props.lang=="fr"?"Mère":"الأم"}
                                                        </option>
                                                        <option key={"girl"} value={"Other"}>
                                                            {props.lang=="fr"?"Autre tuteur":"شخص آخر"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                }
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="civil_status1"
                                                        placeholder={props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Formateur}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un état":"أختر الحالة المدنيّة"}</option>
                                                        <option key={"man"} value={"Mr"}>
                                                            {props.lang=="fr"?"Monsieur":"السّيّد"}
                                                        </option>
                                                        <option key={"woman"} value={"Mme"}>
                                                            {props.lang=="fr"?"Madame":"السّيّدة"}
                                                        </option>
                                                        <option key={"girl"} value={"Mlle"}>
                                                            {props.lang=="fr"?"Mademoiselle":"الآنسة"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name1"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.first_name1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name1"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.last_name1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth1"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.date_of_birth1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            name="place_of_birth1"
                                                            placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.place_of_birth1}
                                                        />
                                                    </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin1"
                                                        placeholder="n° de CIN"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="cin_date1"
                                                        placeholder={props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_date1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin_place1"
                                                        placeholder={props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_place1}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Adresse":"العنوان"}</InputGroup.Text>
                                                    <FormControl
                                                        name="address1"
                                                        placeholder={props.lang=="fr"?"Adresse":"العنوان"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address1}
                                                    />
                                                </InputGroup>
                                                 {props.hasDomicile &&
                                                 <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Domicile":"المسكن"}</InputGroup.Text>
                                                            <FormControl
                                                                name="domicile"
                                                                placeholder={props.lang=="fr"?"Domicile":"المسكن"}
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.domicile}
                                                            />
                                                    </InputGroup>
                                                } 
                                                {props.hasNewAddress &&
                                                 <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nouvelle Adresse":"العنوان الجديد"}</InputGroup.Text>
                                                            <FormControl
                                                                name="new_address"
                                                                placeholder={props.lang=="fr"?"Nouvelle Adresse":"العنوان الجديد"}
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.new_address}
                                                            />
                                                    </InputGroup>
                                                } 
                                                {props.startLivingDate &&
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de début de résidence":"تاريخ بداية السّكن"}</InputGroup.Text>
                                                        <FormControl
                                                            type="date"
                                                            name="start_date_of_living"
                                                            placeholder={props.lang=="fr"?"Date de début de résidence                                                            ":"تاريخ بداية السّكن"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.start_date_of_living}
                                                        />
                                                    </InputGroup>
                                                }
                                            </div>}

                                            {(props.hasResidence) &&
                                            <div>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Résidence":"المسكن"}</InputGroup.Text>
                                                    <FormControl
                                                        name="place_of_residence"
                                                        placeholder={props.lang=="fr"?"Résidence":"المسكن"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.place_of_residence}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de début de résidence":"تاريخ بداية السّكن"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="start_residence"
                                                        placeholder={props.lang=="fr"?"Date de début de résidence":"تاريخ بداية السّكن"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.start_residence}
                                                    />
                                                </InputGroup>
                                               
                                            </div>
                                            }
                                
                                    </Col>
                                    <Col>
                                        {(props.nbrperson > 1) &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.citoyen2}  </h3>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="civil_status2"
                                                        placeholder={props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Formateur}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un état":"أختر الحالة المدنيّة"}</option>
                                                        <option key={"man"} value={"Mr"}>
                                                            {props.lang=="fr"?"Monsieur":"السّيّد"}
                                                        </option>
                                                        <option key={"woman"} value={"Mme"}>
                                                            {props.lang=="fr"?"Madame":"السّيّدة"}
                                                        </option>
                                                        <option key={"girl"} value={"Mlle"}>
                                                            {props.lang=="fr"?"Mademoiselle":"الآنسة"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name2"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.first_name2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name2"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.last_name2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth2"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.date_of_birth2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            name="place_of_birth2"
                                                            placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.place_of_birth2}
                                                        />
                                                    </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin2"
                                                        placeholder={props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="cin_date2"
                                                        placeholder={props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_date2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin_place2"
                                                        placeholder={props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_place2}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Adresse":"العنوان"}</InputGroup.Text>
                                                    <FormControl
                                                        name="address2"
                                                        placeholder={props.lang=="fr"?"Adresse":"العنوان"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address2}
                                                    />
                                                </InputGroup>
                                                {props.parentAuthorisation &&
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Destination":"وجهة السّفر"}</InputGroup.Text>
                                                            <FormControl
                                                                name="destination2"
                                                                placeholder={props.lang=="fr"?"Destination":"وجهة السّفر"}
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.destination2}
                                                            />
                                                    </InputGroup>
                                                }
                                                
                                            </div>}


                                    </Col>
                                </Row>
                                <Row>

                                    <Col >

                                        {(props.nbrperson > 2) &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.citoyen3} </h3>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="civil_status3"
                                                        placeholder={props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Formateur}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un état":"أختر الحالة المدنيّة"}</option>
                                                        <option key={"man"} value={"Mr"}>
                                                            {props.lang=="fr"?"Monsieur":"السّيّد"}
                                                        </option>
                                                        <option key={"woman"} value={"Mme"}>
                                                            {props.lang=="fr"?"Madame":"السّيّدة"}
                                                        </option>
                                                        <option key={"girl"} value={"Mlle"}>
                                                            {props.lang=="fr"?"Mademoiselle":"الآنسة"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name3"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.first_name3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name3"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.last_name3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth3"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.date_of_birth3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            name="place_of_birth3"
                                                            placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.place_of_birth3}
                                                        />
                                                    </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin3"
                                                        placeholder="n° de CIN"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="cin_date3"
                                                        placeholder={props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_date3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin_place3"
                                                        placeholder={props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_place3}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Adresse":"العنوان"}</InputGroup.Text>
                                                    <FormControl
                                                        name="address3"
                                                        placeholder={props.lang=="fr"?"Adresse":"العنوان"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address3}
                                                    />
                                                </InputGroup>
                                                {props.deathStatus && props.nbrperson==3 &&
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}</InputGroup.Text>
                                                        <FormControl
                                                            type="date"
                                                            name="date_of_death"
                                                            placeholder={props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.date_of_death}
                                                        />
                                                    </InputGroup>
                                                }
                                            </div>}
                                    </Col>
                                    {/* <Col style={{ backgroundColor: "#dddddd" }} >

                                        {props.deathStatus && props.nbrperson==3 &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.dateOfDeath} </h3>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}</InputGroup.Text>
                                                        <FormControl
                                                            type="date"
                                                            name="date_of_death"
                                                            placeholder={props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.date_of_death}
                                                        />
                                                    </InputGroup>
                                                </div>

                                            }
                                                </Col> */}

                                    <Col style={{ backgroundColor: "#dddddd" }}  >

                                        {(props.nbrperson > 3) &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.citoyen4} </h3>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="civil_status4"
                                                        placeholder={props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Formateur}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un état":"أختر الحالة المدنيّة"}</option>
                                                        <option key={"man"} value={"Mr"}>
                                                            {props.lang=="fr"?"Monsieur":"السّيّد"}
                                                        </option>
                                                        <option key={"woman"} value={"Mme"}>
                                                            {props.lang=="fr"?"Madame":"السّيّدة"}
                                                        </option>
                                                        <option key={"girl"} value={"Mlle"}>
                                                            {props.lang=="fr"?"Mademoiselle":"الآنسة"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name4"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.first_name4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name4"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.last_name4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth4"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.date_of_birth4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            name="place_of_birth4"
                                                            placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.place_of_birth4}
                                                        />
                                                    </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin4"
                                                        placeholder="n° de CIN"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="cin_date4"
                                                        placeholder={props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_date4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin_place4"
                                                        placeholder={props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_place4}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Adresse":"العنوان"}</InputGroup.Text>
                                                    <FormControl
                                                        name="address4"
                                                        placeholder={props.lang=="fr"?"Adresse":"العنوان"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address4}
                                                    />
                                                </InputGroup>
                                                {props.deathStatus && props.nbrperson==4 &&
                                                    <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}</InputGroup.Text>
                                                            <FormControl
                                                                type="date"
                                                                name="date_of_death"
                                                                placeholder={props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.date_of_death}
                                                            />
                                                        </InputGroup>
                                                }           
                                            </div>}
                                    </Col>
                                    </Row>
                                    <Row>

                                    <Col style={{ backgroundColor: "#dddddd" }}  >

                                        {(props.nbrperson > 4) &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.citoyen5} </h3>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="civil_status5"
                                                        placeholder={props.lang=="fr"?"Etat civil":"الحالة المدنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        as="select"
                                                        id="zone"
                                                        custom
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.Formateur}
                                                    >
                                                        <option>{props.lang=="fr"?"Choisir un état":"أختر الحالة المدنيّة"}</option>
                                                        <option key={"man"} value={"Mr"}>
                                                            {props.lang=="fr"?"Monsieur":"السّيّد"}
                                                        </option>
                                                        <option key={"woman"} value={"Mme"}>
                                                            {props.lang=="fr"?"Madame":"السّيّدة"}
                                                        </option>
                                                        <option key={"girl"} value={"Mlle"}>
                                                            {props.lang=="fr"?"Mademoiselle":"الآنسة"}
                                                        </option>
                                                    </FormControl>
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name5"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.first_name5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name5"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.last_name5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth5"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.date_of_birth5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            name="place_of_birth5"
                                                            placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.place_of_birth5}
                                                        />
                                                    </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"N° de CIN":"رقم بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin5"
                                                        placeholder="n° de CIN"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="cin_date5"
                                                        placeholder={props.lang=="fr"?"Date de libération de la CIN":"تاريخ إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_date5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="cin_place5"
                                                        placeholder={props.lang=="fr"?"Lieu de libération de la CIN":"مكان إصدار بطاقة التّعريف الوطنيّة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.cin_place5}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Adresse":"العنوان"}</InputGroup.Text>
                                                    <FormControl
                                                        name="address5"
                                                        placeholder={props.lang=="fr"?"Adresse":"العنوان"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address5}
                                                    />
                                                </InputGroup>
                                                {props.deathStatus && props.nbrperson==5 &&
                                                    <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}</InputGroup.Text>
                                                            <FormControl
                                                                type="date"
                                                                name="date_of_death"
                                                                placeholder={props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}
                                                                aria-label="Username"
                                                                aria-describedby="basic-addon1"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.date_of_death}
                                                            />
                                                        </InputGroup>
                                                }           
                                            </div>}
                                    </Col>
                                    <Col></Col>
                                    </Row>
                                    {/* <Row>
                                        <Col style={{ backgroundColor: "#dddddd" }} >
                                        {props.deathStatus && props.nbrperson==4 &&
                                            <div>
                                                <h3 style={{ textAlign: "center" }} >{props.dateOfDeath} </h3>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}</InputGroup.Text>
                                                        <FormControl
                                                            type="date"
                                                            name="date_of_death"
                                                            placeholder={props.lang=="fr"?"Date de décès":"تاريخ الوفاة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.date_of_death}
                                                        />
                                                    </InputGroup>
                                                </div>
                                            }

                                        </Col>
                                        <Col>
                                        </Col>
                                    </Row> */}

                                   
                                    {(props.haschildren) && <div style={{ backgroundColor: "#dddddd" }} >

                                        <h3>{props.lang=="fr"?"Les enfants":"الأطفال "}</h3>
                                        <Row>
                                            <Col>
                                        {/* {inputList.map((x, i) => {
                                            return (
                                                <Col>

                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                        <FormControl
                                                            name="child_first_name1"
                                                            placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => handleInputChange(e, i)}
                                                            onBlur={handleBlur}
                                                            value={x.child_first_name1}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                        <FormControl
                                                            name="child_last_name1"
                                                            placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => handleInputChange(e, i)}
                                                            onBlur={handleBlur}
                                                            value={x.child_last_name1}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                        <FormControl
                                                            type="date"
                                                            name="child_date_of_birth1"
                                                            placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => handleInputChange(e, i)}
                                                            onBlur={handleBlur}
                                                            value={x.child_date_of_birth1}
                                                        />
                                                    </InputGroup>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id="basic-addon1">Lieu de naissance</InputGroup.Text>
                                                        <FormControl
                                                            name="child_place_of_birth1"
                                                            placeholder={props.lang=="fr"?"Lieux":"مكان الولادة"}
                                                            aria-label="Username"
                                                            aria-describedby="basic-addon1"
                                                            onChange={e => handleInputChange(e, i)}
                                                            onBlur={handleBlur}
                                                            value={x.child_place_of_birth1}
                                                        />
                                                    </InputGroup>
                                                
                                                 
                                                </Col>
                                            );
                                        })} */}
                                        {inputList.map((element, index) => (
            // <div className="form-inline" key={index}>
            //   <label>Name</label>
            //   <input type="text" name="name" value={element.name || ""} onChange={e => handleChangeInputs(index, e)} />
            //   <label>Email</label>
            //   <input type="text" name="email" value={element.email || ""} onChange={e => handleChangeInputs(index, e)} />
            //   {
            //     index ? 
            //       <button type="button"  className="button remove" onClick={() => removeFormFields(index)}>Remove</button> 
            //     : null
            //   }
            // </div>
                                            <div>

                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Prénom":"الإسم"}</InputGroup.Text>
                                                    <FormControl
                                                        name="first_name"
                                                        placeholder={props.lang=="fr"?"Prénom":"الإسم"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={e => handleChangeInputs(index, e)}
                                                        onBlur={handleBlur}
                                                        value={element.first_name}
                                                    />
                                                    <Button variant="info" onClick={() => addFormFields()}>+</Button>
                                                    {
                                                        index ? 
                                                        <Button variant="danger" onClick={() => removeFormFields(index)}>-</Button> 
                                                        : null
                                                    }
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Nom":"اللّقب"}</InputGroup.Text>
                                                    <FormControl
                                                        name="last_name"
                                                        placeholder={props.lang=="fr"?"Nom":"اللّقب"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={e => handleChangeInputs(index, e)}
                                                        onBlur={handleBlur}
                                                        value={element.last_name}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        type="date"
                                                        name="date_of_birth"
                                                        placeholder={props.lang=="fr"?"Date de naissance":"تاريخ الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={e => handleChangeInputs(index, e)}
                                                        onBlur={handleBlur}
                                                        value={element.date_of_birth}
                                                    />
                                                </InputGroup>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">{props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}</InputGroup.Text>
                                                    <FormControl
                                                        name="place_of_birth"
                                                        placeholder={props.lang=="fr"?"Lieu de naissance":"مكان الولادة"}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={e => handleChangeInputs(index, e)}
                                                        onBlur={handleBlur}
                                                        value={element.place_of_birth}
                                                    />
                                                </InputGroup>
                                            
                                                
                                            </div>
                                        ))}
                                    <div className="button-section">
                                    </div>
                                    </Col>
                                    <Col></Col>
                                </Row>

                                        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                                    </div>




                                    }



                                <Col>
                                    <Button

                                        type="submit"
                                        color="info"
                                        variant="info"
                                        onClick={() => {
                                            setData(
                                                {
                                                    number:values.number,
                                                    destination:values.destination,
                                                    person1: {
                                                        first_name: values.first_name1,
                                                        last_name: values.last_name1,
                                                        cin: values.cin1,
                                                        date_of_birth: values.date_of_birth1,
                                                        place_of_birth: values.place_of_birth1,
                                                        address: values.address1,
                                                        cin_date: values.cin_date1,
                                                        cin_place: values.cin_place1,
                                                        civil_status: values.civil_status1,
                                                        role_state:values.role_state1
                                                    },
                                                    person2: {
                                                        first_name: values.first_name2,
                                                        last_name: values.last_name2,
                                                        cin: values.cin2,
                                                        date_of_birth: values.date_of_birth2,
                                                        place_of_birth: values.place_of_birth2,
                                                        address: values.address2,
                                                        cin_date: values.cin_date2,
                                                        cin_place: values.cin_place2,
                                                        civil_status: values.civil_status2,
                                                        destinationTravel: values.destination2
                                                    },
                                                    person3: {
                                                        first_name: values.first_name3,
                                                        last_name: values.last_name3,
                                                        cin: values.cin3,
                                                        date_of_birth: values.date_of_birth3,
                                                        place_of_birth: values.place_of_birth3,
                                                        address: values.address3,
                                                        cin_date: values.cin_date3,
                                                        cin_place: values.cin_place3,
                                                        civil_status: values.civil_status3
                                                    },
                                                    person4: {
                                                        first_name: values.first_name4,
                                                        last_name: values.last_name4,
                                                        cin: values.cin4,
                                                        date_of_birth: values.date_of_birth4,
                                                        place_of_birth: values.place_of_birth4,
                                                        address: values.address4,
                                                        cin_date: values.cin_date4,
                                                        cin_place: values.cin_place4,
                                                        civil_status: values.civil_status4
                                                    },
                                                    person5: {
                                                        first_name: values.first_name5,
                                                        last_name: values.last_name5,
                                                        cin: values.cin5,
                                                        date_of_birth: values.date_of_birth5,
                                                        place_of_birth: values.place_of_birth5,
                                                        address: values.address5,
                                                        cin_date: values.cin_date5,
                                                        cin_place: values.cin_place5,
                                                        civil_status: values.civil_status5
                                                    },
                                                    residence:{
                                                        place_of_residence:values.place_of_residence,
                                                        start_residence:values.start_residence
                                                    },
                                                    date_of_death:values.date_of_death,
                                                    start_date_of_living:values.start_date_of_living,
                                                    new_address:values.new_address,
                                                    paper_title1:values.paper_title1,
                                                    paper_title2:values.paper_title2      
                                                });
                                            handlSubmit(values);
                                            handleShowPDF({
                                                number:values.number,
                                                destination:values.destination,
                                                person1: {
                                                    first_name: values.first_name1,
                                                    last_name: values.last_name1,
                                                    cin: values.cin1,
                                                    date_of_birth: values.date_of_birth1,
                                                    place_of_birth: values.place_of_birth1,
                                                    address: values.address1,
                                                    cin_date: values.cin_date1,
                                                    cin_place: values.cin_place1,
                                                    role_state:values.role_state1,
                                                    civil_status: values.civil_status1,
                                                    domicile: values.domicile,
                                                },
                                                person2: {
                                                    first_name: values.first_name2,
                                                    last_name: values.last_name2,
                                                    cin: values.cin2,
                                                    date_of_birth: values.date_of_birth2,
                                                    place_of_birth: values.place_of_birth2,
                                                    address: values.address2,
                                                    cin_date: values.cin_date2,
                                                    cin_place: values.cin_place2,
                                                    civil_status: values.civil_status2,
                                                    destinationTravel: values.destination2
                                                },
                                                person3: {
                                                    first_name: values.first_name3,
                                                    last_name: values.last_name3,
                                                    cin: values.cin3,
                                                    date_of_birth: values.date_of_birth3,
                                                    place_of_birth: values.place_of_birth3,
                                                    address: values.address3,
                                                    cin_date: values.cin_date3,
                                                    cin_place: values.cin_place3,
                                                    civil_status: values.civil_status3
                                                },
                                                person4: {
                                                    first_name: values.first_name4,
                                                    last_name: values.last_name4,
                                                    cin: values.cin4,
                                                    date_of_birth: values.date_of_birth4,
                                                    place_of_birth: values.place_of_birth4,
                                                    address: values.address4,
                                                    cin_date: values.cin_date4,
                                                    cin_place: values.cin_place4,
                                                    civil_status: values.civil_status4
                                                },
                                                person5: {
                                                    first_name: values.first_name5,
                                                    last_name: values.last_name5,
                                                    cin: values.cin5,
                                                    date_of_birth: values.date_of_birth5,
                                                    place_of_birth: values.place_of_birth5,
                                                    address: values.address5,
                                                    cin_date: values.cin_date5,
                                                    cin_place: values.cin_place5,
                                                    civil_status: values.civil_status5
                                                },
                                                residence:{
                                                    place_of_residence:values.place_of_residence,
                                                    start_residence:values.start_residence
                                                },
                                                children: inputList,
                                                date_of_death:values.date_of_death,
                                                start_date_of_living:values.start_date_of_living,
                                                new_address:values.new_address,
                                                paper_title1:values.paper_title1,
                                                paper_title2:values.paper_title2                                         
                                    });
                                            // console.log(values);
                                        }}
                                    >
                                        {props.lang=="fr"?"Enregistrer":"تسجيل"}
                                    </Button>
                                    {/*    <Button

                                        // type="submit"
                                        color="info"
                                        variant="info"
                                        disabled={data.length = 0}
                                        onClick={() => {
                                            handleShowPDF();
                                            // console.log(values);
                                        }}
                                        style={{ marginLeft: "20px" }}
                                    >
                                        Visualiser
                                    </Button> */}
                                </Col>



                            </form>

                        )}

                    </Formik>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {props.lang=="fr"?"Annuler":"إلغاء"}
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
            </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Formulaire