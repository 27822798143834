import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';

import logo from '../../../../../assets/images/logo1.png'
import slogan from '../../../../../assets/images/slogan_rim.png'
import HeadersSlogan from '../../../Headers/Headers'

import fontarab from '../../../../../assets/fontarabic.ttf'
import fontArabBold from '../../../../../assets/GretaArabicBold.ttf'
import moment from 'moment'
var Maire = require('../../../Maire/Maire_ar')()
var Mairea = "العمدة"
var Maireb = "مساعد العمدة"

Font.register({
    family: 'fontarab',
    src: fontarab
});
Font.register({
    family: 'fontArabBold',
    src: fontArabBold
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'fontarab',
        fontSize: 11,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 74,
        height: 74,
        textAlign: "center"
    },
    slogan: {
        width: 150,
        height: 50,
        textAlign: "center"
    },
    mainContent1: {
        textAlign: "center",
        marginTop: 1,
        marginBottom: 20,
    },
    sloganCenter: {
        marginTop: 5,
        marginLeft: 220
    },
    image: {
        position: 'absolute',
        display: 'block',
        height: '20%',
        width: '20%',
        marginRight: 350,
        marginTop: 30

    },

    logoCenter: {
        marginLeft: 260
    },
    text: {
        fontSize: 10,
        fontFamily: 'fontarab',
        paddingTop: 5,
        lineHeight: 1,
        fontWeight: "bold",
        fontStyle: "oblique",
        textAlign: "center",
    },
    actCenter: {
        width: 500,
        height: 30,
        backgroundColor: "#90bd9d",
        marginTop:25,
        borderBottomRightRadius: 15,
        borderTopRightRadius: 15,
        color: "#000000",
    },
    actLeft:{
        textAlign:'right',
        marginRight:150
    },
    actLeftContentCommune:{
        // marginLeft:'5%',
        width:300,
        marginTop:-40,
        fontSize: 8,
        textAlign:'center'
    },
    contentLine:{
        textAlign:'center',
        marginLeft:'50%',
        marginRight:'30%'
    },
    line:{
        height:0.7,
        backgroundColor:"black"
    },
     actLeftMaire:{
        // textAlign:'left',
        marginLeft:60,
        fontSize:6
        // marginTop:40

    },
    actLeftCommune:{
        // textAlign:'left',
        marginLeft:60,
        // marginTop:-60

    },
    actLeftMaireAr:{
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft:60,
        // marginTop:40

    },
    actLeftCommuneAr:{
        fontFamily: 'fontarab',
        // textAlign:'left',
        marginLeft:60,
        // marginTop:-60

    },
    textNumberContainer:{
        marginTop:-30,
    },
    textNumber2: {
        fontSize: 8,
        fontFamily: 'fontarab',
        textAlign: "right",
        marginRight: 120,
    },
    text1: {
        fontSize: 15,
        fontFamily: 'fontarab',
        textAlign: "center",
    },
    text2: {
        fontSize: 11,
        fontFamily: 'fontArabBold',
        textAlign: "left",
        marginLeft: 5,
    },
    block1: {
        marginLeft: 30,
        fontFamily: 'fontarab',
        marginRight: 30,
        textAlign: "right",

    },
    minContent1: {
        fontFamily: 'fontArabBold',
        height: 20,
        textAlign: "right",
        marginTop: 20,
    },
    minContent2: {
        fontFamily: 'fontarab',
        height: 20,
        textAlign: "right",
        marginTop: 1,
    },
    minContent3: {
        fontFamily: 'fontarab',
        height: 20,
        textAlign: "right",
        marginTop: 1,
        marginRight: 30
    },
    minContent4: {
        fontFamily: 'fontarab',
        height: 20,
        textAlign: "left",
        marginLeft: 30,
        marginTop:20
    },
    minContent5: {
        textAlign: "left",
        marginLeft: 20
    },
    minContent6: {
        borderTop: "none",
        borderBottom: "none",
        height: 20,
        textAlign: "right",
        marginTop: 10,
        marginRight: 15
    },
    minContent7: {
        fontFamily: 'fontArabBold',
        marginTop: 20,
        textAlign: "center"
    },
    minContent9: {
        fontFamily: 'fontarab',
        height: 20,
        textAlign: "right",
        marginTop: 1,
        marginRight: 42
    },
    checkbox: {
        width: 10,
        height: 10,
        borderWidth: 1,
        fontFamily: 'fontarab',
        marginLeft: 20


    },
    checkbox2:{
        width:10,
        height:10,
        borderWidth:1,
        backgroundColor:"#000000"

    },
    checkboxList: {
        flexDirection: 'row-reverse',
        fontFamily: 'fontarab',
        textAlign: "left",
        alignItems: "center",
        alignContent: "center"


    },
    choiceLabel: {

        fontFamily: 'fontarab',
        marginLeft: 20
    },
    signatureContent: {
        flexDirection: 'row',
    },
    signatureParentContent: {
        marginTop: 10,
        marginRight: 60,
         marginLeft: 25,
        width: 90,
        textAlign: "center"
    },
    signatureOne: {
        fontFamily: 'fontArabBold',
        fontSize: 8,
        width: 150,
        textAlign: "center",
        marginLeft: 15,
        marginRight: 85
    },
    signatureTwo: {
        fontFamily: 'fontArabBold',
        fontSize: 8,
        width: 70,
        textAlign: "center",
        // marginLeft: 75,
        // marginRight: 65
    },
    signatureThree: {
        fontFamily: 'fontArabBold',
        fontSize: 8,
        width: 150,
        textAlign: "center",
        // marginLeft: 65,
        // marginRight: 65
    },
    signatureOneEmpty: {
        fontSize: 8,
        width: 70,
        height: 12,
        textAlign: "center",
        marginLeft: 65,
        marginRight: 65
    },
    signatureTwoEmpty: {
        fontSize: 8,
        width: 70,
        height: 23,
        textAlign: "center",
        marginLeft: 65,
        marginRight: 65
    },
    mainContent2: {
        fontFamily: 'fontarab',
        fontSize: 8,
        lineHeight: 1.15,
        flexDirection: 'column',
        marginRight: 20,
        marginLeft: 20,
        height: 30,
        // backgroundColor: '#e4e9ec',
        textAlign: "right",
        marginTop: 110

    },

    textFooter: {
        fontFamily: 'fontarab',
        fontSize: 7,
        color: 'black',
        width: 540,
        textAlign: "right"
    }
});

function    Invoice(props) {
    return( 
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.mainContent1}>
                            <HeadersSlogan number ={props.invoice.number} />
                           {/* <View style={styles.actLeftContentCommune}>
                            <Text style={styles.actLeftCommuneAr}> وزارة الدّاخليّة و اللّامركزيّة</Text>
                                <Text style={styles.actLeftMaire}>MINISTERE DE L'ENTERIEUR ET DE LACENTRALISATION</Text>
                                <View style={styles.contentLine}><View style={styles.line}></View></View>
                                <Text style={styles.actLeftCommuneAr}>{localStorage.getItem("Delegation_ar")} بلديّة </Text>
                                <Text style={styles.actLeftMaire}>COMMUNE DE  {localStorage.getItem("Delegation")}</Text>
                                <Text style={styles.actLeftCommuneAr}>العمدة</Text>
                                <Text style={styles.actLeftMaire}>Le Maire</Text>
                            </View>
                            
                            <View style={styles.textNumberContainer}>
                                <Text style={styles.textNumber2}>{localStorage.getItem("city")}{" le "}{moment().format('DD/MM/YYYY')}{" في "}{" "}{localStorage.getItem("city_ar")}</Text>
                                <Text style={styles.textNumber2}>Numéro : {props.invoice.number} :الرّقم </Text>
                            </View> */}

                <View style={styles.actCenter}>
                    <Text style={styles.text1}>  ( مثول الشّخصي لوكيل مقدّم الطّلب و الشّاهدين )  شهادة مطابقة </Text>
                </View>
               
                <View style={styles.block1}>
                <Text style={styles.minContent1}> {props.invoice.destination} الوجهة : </Text>
                <Text style={styles.minContent2}>.مدّة الصّلاحيّة: سنة واحدة ابتداء من تاريخ التّوقيع</Text>
                    <Text style={styles.minContent2}> نفيد بعد فحص المستندات التّبريريّة، و بناء على تصريح:   </Text>
                    <Text style={styles.minContent3}>   {props.invoice.person1.first_name+ " "+ props.invoice.person1.last_name}الإسم و اللّقب : </Text>
                    <Text style={styles.minContent3}>   <Text> {props.invoice.person1.cin} بطاقة التّعريف الوطنيّة أو جواز السّفر رقم </Text></Text>
                    <Text style={styles.minContent3}>  <Text>: وكيل مقدّم الطّلب و على شهادة كلّ من   </Text> <Text>{props.invoice.person1.cin_date?moment(props.invoice.person1.cin_date).format('YYYY/MM/DD'):""}</Text>  الصّادرة بتاريخ </Text>
                    


                    <Text style={styles.minContent3}>   {props.invoice.person2.first_name+ " "+ props.invoice.person2.last_name}الإسم و اللّقب : {" (1 "}</Text>
                    <Text style={styles.minContent3}>  <Text>{props.invoice.person2.cin_date?moment(props.invoice.person2.cin_date).format('YYYY/MM/DD'):""} بتاريخ</Text><Text> (ة)الصّادر</Text> <Text> {props.invoice.person2.cin} بطاقة التّعريف الوطنيّة رقم </Text></Text>

                    <Text style={styles.minContent3}>   {props.invoice.person3.first_name+ " "+ props.invoice.person3.last_name}الإسم و اللّقب : {" (2 "}</Text>
                    <Text style={styles.minContent3}> <Text>{props.invoice.person3.cin_date?moment(props.invoice.person3.cin_date).format('YYYY/MM/DD'):""} بتاريخ</Text><Text> (ة)الصّادر</Text>  <Text> {props.invoice.person3.cin} بطاقة التّعريف الوطنيّة رقم </Text></Text>
             
                    <Text style={styles.minContent2}>: أنّ التّسميات التّي ظهرت في الوثيقتين الرّسميّتين التّاليتين  </Text>
                    <Text style={styles.minContent3}>{props.invoice.paper_title1} : 1</Text>
                    <Text style={styles.minContent3}>{props.invoice.paper_title2} : 2</Text>
                    <Text style={styles.minContent2}><Text>{props.invoice.person4.first_name+" "+props.invoice.person4.last_name}</Text><Text> تعني نفس الشّخص </Text> (ة)باسم المعني</Text>

                    <Text style={styles.minContent2}><Text> أمامنا و الشّاهدان،للتّمتّع بما يترتّب عليها من حقوق .</Text><Text> (ة)ها المصرّح</Text><Text>(ت)هذه الشّهادة التّي وقّع</Text><Text> (ا)و قد سلّمنا له</Text></Text>
                    {/* <Text style={styles.minContent4}>{moment().format("YYYY/MM/DD")} بتاريخ {" "}{localStorage.getItem("Delegation_ar")} حرّر في  </Text> */}
                    <View >
                            <View style={styles.signatureContent}>
                            <View style={styles.signatureParentContent}>
                                      <Text style={styles.signatureTwo}>{localStorage.getItem("Maireouautre")==="true"? Maireb:Mairea} </Text>
                                    <Text style={styles.signatureTwoEmpty}></Text>
                                    <Text style={styles.signatureTwo}>………………………………</Text>
                                </View>
                               
                            <View style={styles.signatureParentContent}>
                                    <Text style={styles.signatureThree}> توقيع شاهدين  يفيدان  على شرفهما، </Text>
                                    <Text style={styles.signatureThree}> بدقّة المعلومات الواردة أعلاه  </Text>
                                    <Text style={styles.signatureThree}>   ………………………………………  1  </Text>
                                    <Text style={styles.signatureThree}>   ………………………………………  2 </Text>
                                </View>
                               
                              
                                <View style={styles.signatureParentContent}>
                                    <Text style={styles.signatureOne}>توقيع وكيل مقدّم الطّلب يفيد</Text>
                                    <Text style={styles.signatureOne}>صحّة المعلومات الواردة أعلاه</Text>
                                    <Text style={styles.signatureOneEmpty}></Text>
                                    <Text style={styles.signatureOne}>………………………………………</Text>
                                </View>
                            </View>
                        </View>
                </View>

            </View>

          
            <View style={styles.mainContent2}>
                <Text style={styles.textFooter}>إن أفعال المحاكاة أو التزوير أو التحوير أو محاولة استخدام وثائق صادرة عن الإدارات العمومية بغرض إثبات حق أو علاوة أو صفة أو منح ترخيص، عن طريق الإدلاء ببيانات كاذبة أو بانتحال اسم مزيف </Text>
                <Text style={styles.textFooter}>، أو صفة زائفة، أو بتقديم معلومات زائفة، و تزوير الشهادات أو الإفادات،فضلا عن قيام موظف بإصدار هذه الوثائق أو التسبب في ذلك، هي مخالفات منصوص عليها و تعاقب بالغرمات والحبس كما تنص على ذلك</Text>
                <Text style={styles.textFooter}> المادتان  941 و 051 من القانون الجنائي الموريتاني.</Text>
            </View>
        </Page>
    </Document>
)
                };

export default Invoice