import React from 'react';

const Footer = () => {
    return (
        <footer className="footer text-center">
            All Rights Reserved  <a href="https://mdscresoft-group.com/"> https://mdscresoft-group.com/</a>.
        </footer>
    );
}
export default Footer;
