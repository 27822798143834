/*--------------------------------------------------------------------------------*/
/*                                  starter                                    */
/*--------------------------------------------------------------------------------*/
import FirstDashboard from '../views/starter/starter.jsx';
/*--------------------------------------------------------------------------------*/
/*                           Ui-components Dropdown                               */
/*--------------------------------------------------------------------------------*/
import Act1_a from '../views/ui-components/Act1_a/Act1_a';
import Act1_b from '../views/ui-components/Act1_b/Act1_b';
import Act1_b_ar from '../views/ui-components/Act1_b_ar/Act1_b_ar'
import Act2_a from '../views/ui-components/Act2_a/Act2_a';
import Act2_a_ar from '../views/ui-components/Act2_a_ar/Act2_a_ar'
import Act2_b from '../views/ui-components/Act2_b/Act2_b';
import Act2_b_ar from '../views/ui-components/Act2_b_ar/Act2_b_ar'
import Act3_a from '../views/ui-components/Act3_a/Act3_a';
import Act3_a_ar from '../views/ui-components/Act3_a_ar/Act3_a_ar'
import Act3_b from '../views/ui-components/Act3_b/Act3_b';
import Act3_b_ar from '../views/ui-components/Act3_b_ar/Act3_b_ar'
import Act5_ar from '../views/ui-components/Act5_ar/Act5_ar'
import Act4_a from '../views/ui-components/Act4_a/Act4_a'
import Act4_a_ar from '../views/ui-components/Act4_a_ar/Act4_a_ar'
import Act4_b from '../views/ui-components/Act4_b/Act4_b'
import Act4_b_ar from '../views/ui-components/Act4_b_ar/Act4_b_ar'
import Act5 from '../views/ui-components/Act5/Act5';
import Act6_a from '../views/ui-components/Act6_a/Act6_a'
import Act6_a_ar from '../views/ui-components/Act6_a_ar/Act6_a_ar'
import Act6_b from '../views/ui-components/Act6_b/Act6_b'
import Act6_b_ar from '../views/ui-components/Act6_b_ar/Act6_b_ar'
import Act7 from '../views/ui-components/Act7/Act7';
import Act7_ar from '../views/ui-components/Act7_ar/Act7_ar'
import Act8_ar from '../views/ui-components/Act8_ar/Act8_ar'
import Act8 from '../views/ui-components/Act8/Act8';
import Act9 from '../views/ui-components/Act9/Act9';
import Act9_ar from '../views/ui-components/Act9_ar/Act9_ar'
import Act10 from '../views/ui-components/Act10/Act10';
import Act10_ar from '../views/ui-components/Act10_ar/Act10_ar'
import Act11_a from '../views/ui-components/Act11_a/Act11_a';
import Act11_a_ar from '../views/ui-components/Act11_a_ar/Act11_a_ar'
import Act11_b from '../views/ui-components/Act11_b/Act11_b';
import Act11_b_ar from '../views/ui-components/Act11_b_ar/Act11_b_ar'
import Act12 from '../views/ui-components/Act12/Act12';
import Act12_ar from '../views/ui-components/Act12_ar/Act12_ar'
import Act13 from '../views/ui-components/Act13/Act13';
import Act13_ar from '../views/ui-components/Act13_ar/Act13_ar'
import Act14 from '../views/ui-components/Act14/Act14';
import Act14_ar from '../views/ui-components/Act14_ar/Act14_ar'
import Act15 from '../views/ui-components/Act15/Act15';
import Act15_ar from '../views/ui-components/Act15_ar/Act15_ar'

import Act16 from '../views/ui-components/Act16/Act16';
import Act16_ar from '../views/ui-components/Act16_ar/Act16_ar'
import Act17 from '../views/ui-components/Act17/Act17';
import Act17_ar from '../views/ui-components/Act17_ar/Act17_ar'
// import Template from '../views/ui-components/Template/Template'
// import Template_ar from '../views/ui-components/Template_ar/Template_ar'

// import Resume from '../views/ui-components/Act9.js/index'
// import Badges from '../views/ui-components/badge.jsx';
// import Buttons from '../views/ui-components/button.jsx';
// import Cards from '../views/ui-components/cards.jsx';
// import LayoutComponent from '../views/ui-components/layout.jsx';
// import PaginationComponent from '../views/ui-components/pagination.jsx';
// import PopoverComponent from '../views/ui-components/popover.jsx';
// import TooltipComponent from '../views/ui-components/tooltip.jsx';
///////////////////////////////////////
import Act1_a_ar from '../views/ui-components/Act1_a_ar/Act1_a_ar'
var ThemeRoutes = [
  {
    path: '/starter/starter',
    subname:"",
    name: 'Dashboard',
    icon: 'mdi mdi-adjust',
    component: FirstDashboard
  },


  {
    path: '/ui-components/Act1_a',
    subname:"Certificat de vie individuelle (Comparution personnelle du demandeur)",
    name: 'Certificat de vie individuelle',
    icon: 'mdi mdi-file',
    component: Act1_a
  },

  {
    path: '/ui-components/ar_Act1_a',
    subname:"شهادة حياة فردية (مثول شخصي لمقدم الطلب)",
    name: 'شهادة حياة فردية',
    icon: 'mdi mdi-file',
    component: Act1_a_ar
  },
  {
    path: '/ui-components/Act1_b',
    subname:"Certificat de vie individuelle (Comparution d’un mandataire en lieu et place personnelle du demandeur)",
    name: 'Certificat de vie individuelle',
    icon: 'mdi mdi-file',
    component: Act1_b
  },
  {
    path: '/ui-components/ar_Act1_b',
    subname:"شهادة حياة فردية (المثول الشخصي للوكيل بدل و مكان مقدم الطلب)",
    name: 'شهادة حياة فردية',
    icon: 'mdi mdi-file',
    component: Act1_b_ar
  },

 
  {
    path: '/ui-components/Act2_a',
    subname:"Certificat de vie collective (Comparution personnelle du demandeur)",
    name: 'Certificat de vie collective',
    icon: 'mdi mdi-file',
    component: Act2_a
  },
  {
    path: '/ui-components/ar_Act2_a',
    subname:"شهادة حياة جماعية (مثول شخصي لمقدم الطلب)",
    name: 'شهادة حياة جماعية',
    icon: 'mdi mdi-file',
    component: Act2_a_ar
  },
  {
    path: '/ui-components/Act2_b',
    subname:"Certificat de vie collective (Comparution du mandataire du demandeur)",
    name: 'Certificat de vie collective',
    icon: 'mdi mdi-file',
    component: Act2_b
  },
  {
    path: '/ui-components/ar_Act2_b',
    subname:"شهادة حياة جماعية (مثول شخصي لوكيل مقدم الطلب)",
    name: 'شهادة حياة جماعية',
    icon: 'mdi mdi-file',
    component: Act2_b_ar
  },


  {
    path: '/ui-components/Act3_a',
    subname:"Certificat de non mariage (Comparution personnelle du demandeur)",
    name: 'Certificat de non mariage',
    icon: 'mdi mdi-file',
    component: Act3_a
  },
  {
    path: '/ui-components/ar_Act3_a',
    subname:"شهادة عدم زواج (مثول شخصي لمقدم الطلب)",
    name: 'شهادة عدم زواج',
    icon: 'mdi mdi-file',
    component: Act3_a_ar
  },


  {
    path: '/ui-components/Act3_b',
    subname:"Certificat de non mariage (Comparution mandataire du demandeur)",
    name: 'Certificat de non mariage',
    icon: 'mdi mdi-file',
    component: Act3_b
  },
  {
    path: '/ui-components/ar_Act3_b',
    subname:"شهادة عدم زواج (مثول شخصي لوكيل مقدم الطلب)",
    name: 'شهادة عدم زواج',
    icon: 'mdi mdi-file',
    component: Act3_b_ar
  },
  {
    path: '/ui-components/Act4_a',
    subname:"Certificat de non divorce et de non remariage (Comparution personnelle du demandeur)",
    name: 'Certificat de non divorce et de non remariage',
    icon: 'mdi mdi-file',
    component: Act4_a
  },
  {
    path: '/ui-components/ar_Act4_a',
    subname:"شهادة عدم الطلاق و عدم الزواج من جديد (مثول شخصي لمقدم الطلب)",
    name: 'شهادة عدم الطلاق و عدم الزواج من جديد',
    icon: 'mdi mdi-file',
    component: Act4_a_ar
  },
  {
    path: '/ui-components/Act4_b',
    subname:"Certificat de non divorce et de non remariage (Comparution mandataire du demandeur)",
    name: 'Certificat de non divorce et de non remariage',
    icon: 'mdi mdi-file',
    component: Act4_b
  },
  {
    path: '/ui-components/ar_Act4_b',
    subname:"شهادة عدم الطلاق و عدم الزواج مرة أخرى (مثول شخصي لوكيل مقدم الطلب)",
    name: 'شهادة عدم الطلاق و عدم الزواج مرة أخرى',
    icon: 'mdi mdi-file',
    component: Act4_b_ar
  },
  {
    path: '/ui-components/Act5',
    subname:"Certificat de résidence (Comparution personnelle du demandeur exigée)",
    name: 'Certificat de résidence',
    icon: 'mdi mdi-file',
    component: Act5
  },
  {
    path: '/ui-components/ar_Act5',
    subname:"شهادة سكن (المثول الشخصي لمقدم الطلب إلزامي)",
    name: 'شهادة سكن',
    icon: 'mdi mdi-file',
    component: Act5_ar
  },
  {
    path: '/ui-components/Act6_a',
    subname:"Certificat d’indigence (Comparution personnelle du demandeur)",
    name: 'Certificat d’indigence',
    icon: 'mdi mdi-file',
    component: Act6_a
  },
  {
    path: '/ui-components/ar_Act6_a',
    subname:"شهادة عوز (مثول شخصي لمقدم الطلب)",
    name: 'شهادة عوز',
    icon: 'mdi mdi-file',
    component: Act6_a_ar
  },
  {
    path: '/ui-components/Act6_b',
    subname:"Certificat d’indigence (Comparution mandataire du demandeur)",
    name: 'Certificat d’indigence',
    icon: 'mdi mdi-file',
    component: Act6_b
  },
  {
    path: '/ui-components/ar_Act6_b',
    subname:"شهادة عوز (مثول شخصي  لوكيل مقدم الطلب)",
    name: 'شهادة عوز',
    icon: 'mdi mdi-file',
    component: Act6_b_ar
  },
  {
    path: '/ui-components/Act7',
    subname:"Autorisation parentale (Comparution personnelle du demandeur exigée)",
    name: 'Autorisation parentale',
    icon: 'mdi mdi-file',
    component: Act7
  },
  {
    path: '/ui-components/ar_Act7_ar',
    subname:"ترخيص أبوي (المثول الشخصي لمقدم الطلب إلزامي)",
    name: 'ترخيص أبوي',
    icon: 'mdi mdi-file',
    component: Act7_ar
  },




  {
    path: '/ui-components/Act8',
    subname:"Certificat d’hébergement (Comparution personnelle du demandeur exigée)",
    name: 'Certificat d’hébergement',
    icon: 'mdi mdi-file',
    component: Act8
  },
  {
    path: '/ui-components/ar_Act8',
    subname:"شهادة إيواء (المثول الشخصي لمقدم الطلب إلزامي)",
    name: 'شهادة إيواء',
    icon: 'mdi mdi-file',
    component: Act8_ar
  },


  {
    path: '/ui-components/Act9',
    subname:"Attestation de prise en charge (Comparution personnelle de l’usager est exigée)",
    name: 'Attestation de prise en charge',
    icon: 'mdi mdi-file',
    component: Act9
  },
  {
    path: '/ui-components/ar_Act9',
    subname:"إفادة تكفل (المثول الشخصي للمستخدم إلزامي)",
    name: 'إفادة تكفل',
    icon: 'mdi mdi-file',
    component: Act9_ar
  },
  {
    path: '/ui-components/Act10',
    subname:"Attestation de changement de résidence (Comparution personnelle de l’usager exigée)",
    name: 'Attestation de changement de résidence',
    icon: 'mdi mdi-file',
    component: Act10
  },
  {
    path: '/ui-components/ar_Act10',
    subname:"إفادة رحيل أو تغيير مكان السّكن (المثول الشّخصي للمستخدم إلزامي)",
    name: 'إفادة رحيل أو تغيير مكان السّكن',
    icon: 'mdi mdi-file',
    component: Act10_ar
  },

  
  {
    path: '/ui-components/Act11_a',
    subname:"Certificat de conformité (Comparution personnelle du demandeur et des témoins)",
    name: 'Certificat de conformité',
    icon: 'mdi mdi-file',
    component: Act11_a
  },
  {
    path: '/ui-components/ar_Act11_a',
    subname:"شهادة مطابقة (المثول الشّخصي لمقدّم الطّلب و الشّاهدين )",
    name: 'شهادة مطابقة',
    icon: 'mdi mdi-file',
    component: Act11_a_ar
  },
  
  {
    path: '/ui-components/Act11_b',
    subname:"Certificat de conformité (Comparution mandataire du demandeur et des témoins)",
    name: 'Certificat de conformité',
    icon: 'mdi mdi-file',
    component: Act11_b
  },
  {
    path: '/ui-components/ar_Act11_b',
    subname:"شهادة مطابقة (مثول الشخصي لوكيل مقدم الطلب و الشاهدين )",
    name: 'شهادة مطابقة',
    icon: 'mdi mdi-file',
    component: Act11_b_ar
  },
  
  {
    path: '/ui-components/Act12',
    subname:"Attestation coutume (La correspondance officielle de saisine de l’administration émettrice est exigée)",
    name: 'Attestation coutume',
    icon: 'mdi mdi-file',
    component: Act12
  },
  {
    path: '/ui-components/ar_Act12',
    subname:"إفادة عرفيّة (إلزاميّة المراسلة الرّسميّة للإدارة التّي تصدرها)",
    name: 'إفادة عرفيّة',
    icon: 'mdi mdi-file',
    component: Act12_ar
  },

  
  {
    path: '/ui-components/Act13',
    subname:"Attestation de non salarié (Comparution personnelle du demandeur exigée)",
    name: 'Attestation de non salarié',
    icon: 'mdi mdi-file',
    component: Act13
  },
  {
    path: '/ui-components/ar_Act13',
    subname:"إفادة عدم تلقّي راتب (المثول الشّخصي لمقدّم الطّلب إلزامي)",
    name: 'إفادة عدم تلقّي راتب',
    icon: 'mdi mdi-file',
    component: Act13_ar
  },

  
  {
    path: '/ui-components/Act14',
    subname:"Attestation de non emploi (Comparution personnelle du demandeur exigée)",
    name: 'Attestation de non emploi',
    icon: 'mdi mdi-file',
    component: Act14
  },
  {
    path: '/ui-components/ar_Act14',
    subname:"إفادة عدم العمل (المثول الشّّخصي لمقدّم الطّلب إلزامي)",
    name: 'إفادة عدم العمل',
    icon: 'mdi mdi-file',
    component: Act14_ar
  },
  {
    path: '/ui-components/Act15',
    subname:"Attestation d’analphabète (Comparution personnelle de l’usager exigée)",
    name: 'Attestation d’analphabète',
    icon: 'mdi mdi-file',
    component: Act15
  },
  {
    path: '/ui-components/ar_Act15',
    subname:"إفادة أميّة (المثول الشّخصي للمستخدم إلزامي)",
    name: 'إفادة أميّة',
    icon: 'mdi mdi-file',
    component: Act15_ar
  },
  
  {
    path: '/ui-components/Act16',
    subname:"Attestation de monogamie (Comparution personnelle de l’usager est exigée)",
    name: 'Attestation de monogamie',
    icon: 'mdi mdi-file',
    component: Act16
  },
  {
    path: '/ui-components/ar_Act16',
    subname:"إفادة زواج أحادي (المثول الشّخصي للمستخدم إلزامي)",
    name: 'إفادة زواج أحادي',
    icon: 'mdi mdi-file',
    component: Act16_ar
  },
  {
    path: '/ui-components/Act17',
    subname:"Attestation de Polygamie (Comparution personnelle de l’usager est exigée)",
    name: 'Attestation de Polygamie',
    icon: 'mdi mdi-file',
    component: Act17
  },
  {
    path: '/ui-components/ar_Act17',
    subname:"إفادة تعدّد الزّوجات (المثول الشّخصي للمستخدم إلزامي)",
    name: 'إفادة تعدّد الزّوجات',
    icon: 'mdi mdi-file',
    component: Act17_ar
  },
  // {
  //   path: '/ui-components/Template',
  //   name: 'Template',
  //   icon: 'mdi mdi-file',
  //   component: Template
  // },
  // {
  //   path: '/ui-components/ar_Template',
  //   name: 'Template_ar',
  //   icon: 'mdi mdi-file',
  //   component: Template_ar
  // },
  // {
  //   path: '/ui-components/Resume',
  //   name: 'Resume',
  //   icon: 'mdi mdi-file',
  //   component: Resume
  // },


  // {
  //   path: '/ui-components/badge',
  //   name: 'Act2',
  //   icon: 'mdi mdi-arrange-send-backward',
  //   component: Badges
  // },
  // {
  //   path: '/ui-components/button',
  //   name: 'Act3',
  //   icon: 'mdi mdi-toggle-switch',
  //   component: Buttons
  // },
  // {
  //   path: '/ui-components/card',
  //   name: 'Act5',
  //   icon: 'mdi mdi-credit-card-multiple',
  //   component: Cards
  // },
  // {
  //   path: '/ui-components/layout',
  //   name: 'Act6',
  //   icon: 'mdi mdi-apps',
  //   component: LayoutComponent
  // },
  // {
  //   path: '/ui-components/pagination',
  //   name: 'Act7',

  //   icon: 'mdi mdi-priority-high',
  //   component: PaginationComponent
  // },
  // {
  //   path: '/ui-components/popover',
  //   name: 'Act8',

  //   icon: 'mdi mdi-pencil-circle',
  //   component: PopoverComponent
  // },
  // {
  //   path: '/ui-components/tooltip',
  //   name: 'Act9',

  //   icon: 'mdi mdi-image-filter-vintage',
  //   component: TooltipComponent
  // },
  // {
  //   path: '/',
  //   pathTo: '/starter/starter',
  //   name: 'Dashboard',
  //   redirect: true
  // }
];
export default ThemeRoutes;
