import React, { Component, Fragment,useRef } from 'react';
import { PDFViewer } from '@react-pdf/renderer'
import Invoice from './components/reports/Invoice'
import invoice from './data/invoice'
 import Get_cin_four from '../Formulaire/Formulaire'
import Formulaire from '../Formulaire/Formulaire'

// import logo from './logo.svg';
import './App.css';

function Act1_2(props) {
  const refModal = useRef();
  const [data, setData] = React.useState(
    {
      number:"",
      destination:"",
      person1: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        cin_date: "",
        cin_place: "",
        civil_state:""
      },
      person2: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        cin_date: "",
        cin_place: "",
        civil_state:""
      },
      person3: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        cin_date: "",
        cin_place: "",
        civil_state:""
      },
      person4: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        cin_date: "",
        cin_place: "",
        civil_state:""
      },
      person5: {
        id: "",
        first_name: "",
        last_name: "",
        cin: "",
        date_of_birth: "",
        place_of_birth: "",
        address: "",
        cin_date: "",
        cin_place: "",
        civil_state:""
      },
      residence:{
        place_of_residence:"",
        start_residence:""
      },
      children:[]


    }
  ); // the lifted state

  const sendDataToParent = (index) => { // the callback. Use a better name
    console.log(index);
    setData(index);
    console.log("data=>" + JSON.stringify(data));
    console.log("data type=>" + typeof (data));
  };
  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <div className="modal" ref={refModal} />
      <Formulaire refModal={refModal} lang={"fr"} nbrperson={3} haschildren={true} namebutton={"Certificat de vie collective (Comparution personnelle du demandeur)"}     citoyen1={"Personne 1"}   citoyen2={"Personne 2"}  citoyen3={"Personne 3"}  citoyen4={"Personne 4"}sendDataToParent={sendDataToParent}  />
      {/* <Get_cin_four lang={"fr"} nbrperson={3} namebutton={" Certificat de vie collective "}     citoyen1={"Personne 1"}   citoyen2={"Personne 2"}  citoyen3={"Personne 3"}  citoyen4={"Personne 4"}sendDataToParent={sendDataToParent} /> */}

      <PDFViewer width="100%" height="800" className="100%" >
        <Invoice invoice={data} />
      </PDFViewer>
    </div>
  );

}

export default Act1_2;